import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { getRegionString, getWinemakerString } from '@/utils/stringTransforms';
import { getUrlFromPath, getPathFromTags } from '@/utils/routing';
import { getSanitizedPrice } from '@/utils/pricing';

import media from '@/styles/media';
import scale from '@/styles/scale';

import ProductImageGallery from '@/components/ProductImageGallery';
import ProductInfo from '@/components/ProductInfo';
import RelatedProducts from '@/components/RelatedProducts';
import SchemaOrg from '@/components/SchemaOrg';
import Head from '@/components/Head';

const isBrowser = typeof window !== 'undefined';

const ProductTop = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

const ProductTopHalf = styled.div`
  margin-bottom: ${scale(1.5)};
  width: 100%;

  ${media.md`
    margin-bottom: ${scale(6)};
    width: 50%;
  `}
`;

export default ({ pageContext, data }) => {
  const { wine } = data.graph;
  const { relatedWines, producer } = wine;

  const images = wine.images.length
    ? wine.images.slice(0, 1)
    : ['placeholder.jpg'];

  const nameString = wine.vintage
    ? [wine.name, wine.vintage].join(' ')
    : wine.name;

  const isAvailable =
    wine.shopifyProduct && wine.shopifyProduct.availableForSale;

  const price = isAvailable
    ? wine.shopifyProduct.priceRange.minVariantPrice.amount
    : null;

  const producerString = producer.winemakerMoreCommon
    ? `${producer.name} (${getWinemakerString(producer.winemakers)})`
    : producer.name;

  const productDescription = `${nameString} by ${producerString} from ${getRegionString(
    wine.producer.regions,
    true
  )}.`;

  if (isBrowser) {
    window.dataLayer.push({
      pageType: 'productDetailPage',
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          products: [
            {
              name: nameString,
              id: wine.sku,
              sku: wine.sku,
              handle: `${wine.slug}-${producer.slug}`,
              price:
                isAvailable &&
                getSanitizedPrice(
                  wine.shopifyProduct.priceRange.minVariantPrice.amount
                ),
              brand: producer.name,
              quantity: 1,
            },
          ],
        },
      },
    });
  }

  return (
    <>
      <Head
        title={`${nameString} by ${producerString}`}
        description={productDescription}
        image={images[0]}
      />
      <SchemaOrg
        type="breadcrumbs"
        navigationTags={pageContext.defaultNavigationTags}
      />

      <SchemaOrg
        type="product"
        name={nameString}
        description={productDescription}
        images={images}
        producer={producerString}
        url={getUrlFromPath(getPathFromTags(pageContext.defaultNavigationTags))}
        price={price}
        isAvailable={isAvailable}
        sku={wine.sku}
      />

      <ProductTop>
        <ProductTopHalf>
          <ProductImageGallery
            alt={`${nameString} by ${producerString}`}
            images={images}
          />
        </ProductTopHalf>

        <ProductTopHalf>
          <ProductInfo wine={wine} />
        </ProductTopHalf>
      </ProductTop>

      <RelatedProducts
        products={relatedWines.wines}
        relationInfo={relatedWines.relationInfo}
        headlineParts={[
          {
            before: 'Other',
            after: 'wines',
            tagTypes: ['body', 'type'],
          },
          {
            before: ', with a',
            after: 'profile',
            tagTypes: ['style'],
          },
        ]}
      />
    </>
  );
};

export const query = graphql`
  query($id: ID!) {
    graph {
      wine(where: { id: $id }) {
        name
        vintage
        sku
        slug
        producer {
          name
          slug
          winemakerMoreCommon
          winemakers {
            firstName
            lastName
          }
          legalName
          street
          postcode
          city
          regions {
            id
            name
            slug
            parentRegion {
              id
              name
            }
            country {
              name
              slug
            }
          }
        }
        wineVarieties {
          part
          variety {
            name
          }
        }
        images
        type
        color
        body
        abv
        sulfiteLevel
        drinkBefore
        drinkingTemperature
        decant
        turnBottle
        productionVolume
        bottleSize
        styles {
          name
          slug
        }
        shopifyProduct {
          availableForSale
          descriptionHtml
          priceRange {
            minVariantPrice {
              amount
            }
          }
          variants {
            edges {
              node {
                id
                inventoryQuantity
              }
            }
          }
        }
        relatedWines {
          wines {
            ...WineData
          }
          relationInfo
        }
      }
    }
  }
`;
