import React from 'react';
import styled from 'styled-components';

import media from '@/styles/media';
import scale from '@/styles/scale';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';

import PriceInfo from './PriceInfo';
import AddToCart from './AddToCart';

const Container = styled(Grid.Item)`
  margin-bottom: ${scale(2.25)};

  ${media.md`
    order: 2;
    margin-bottom: ${scale(3.5)};
  `}
`;

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${props => props.theme.color.base};
  border-width: 1px 0;
  padding-top: ${scale(1.5)};
  padding-bottom: ${scale(1.5)};

  ${media.md`
    padding-top: ${scale(2.5)};
    padding-bottom: ${scale(2.5)};
  `}
`;

const SoldOut = styled(Heading).attrs({ h: 2 })`
  padding-top: ${scale(0.5)};

  ${media.md`
    padding-top: ${scale(0.375)};
  `}
`;

export default ({
  variantId,
  isAvailable,
  quantityAvailable,
  price,
  bottleSize,
  onAddToCart,
  ...props
}) => (
  <Container w={[1]} {...props}>
    <Inner>
      <PriceInfo price={price} bottleSize={bottleSize} />

      {isAvailable && variantId ? (
        <AddToCart
          variantId={variantId}
          quantityAvailable={quantityAvailable}
          onAddToCart={onAddToCart}
        />
      ) : (
        <SoldOut as="p">Sold out</SoldOut>
      )}
    </Inner>
  </Container>
);
