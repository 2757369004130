import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Icon from '@/atoms/Icon';
import NavigationLink from '@/atoms/NavigationLink';

import scale from '@/styles/scale';
import { buttonLabel, buttonLabelSmall } from '@/styles/typography';

const arrowWidth = scale(1.5 + 0.5);

const ArrowRight = styled(Icon).attrs({ type: 'arrowRight' })`
  padding-left: ${scale(0.5)};
  transition: all 0.15s ease-out;
`;

const ArrowLeft = styled(Icon).attrs({ type: 'arrowRight' })`
  padding-right: ${scale(0.5)};
  transition: all 0.15s ease-out;
`;

const LabelWrapper = styled.span`
  display: inline-block;
  margin-right: -${arrowWidth};
`;

const Label = styled.span`
  display: inline-flex;
  align-items: center;
  transition: all 0.15s ease-out;
`;

const Button = styled.button`
  ${props => (props.size === 'default' ? buttonLabel : buttonLabelSmall)}
  display: inline-flex;
  flex-shrink: 0;
  border: 0;
  cursor: pointer;
  color: ${props => props.theme.color.base};
  outline: none;
  overflow: hidden;

  ${Label} {
    transform: translateX(-${arrowWidth});
  }

  ${ArrowLeft} {
    opacity: 0;
  }

  ${ArrowRight} {
    opacity: 1;
  }

  :hover,
  :focus {
    ${Label} {
      transform: translateX(0);
    }

    ${ArrowLeft} {
      opacity: 1;
    }

    ${ArrowRight} {
      opacity: 0;
    }
  }
`;

export default ({
  size = 'default',
  to = null,
  href = null,
  tags = null,
  hrefTarget = null,
  children,
  ...props
}) => {
  const buttonProps = {
    size,
    as: 'button',
  };

  if (tags) {
    buttonProps.as = NavigationLink;
    buttonProps.tags = tags;
  }

  if (to) {
    buttonProps.as = Link;
    buttonProps.to = to;
  }

  if (href) {
    buttonProps.as = 'a';
    buttonProps.href = href;
    buttonProps.target = hrefTarget;
  }

  return (
    <Button {...buttonProps} {...props}>
      <LabelWrapper>
        <Label>
          <ArrowLeft type="arrowRight" />

          {children}

          <ArrowRight type="arrowRight" />
        </Label>
      </LabelWrapper>
    </Button>
  );
};
