import React from 'react';
import styled from 'styled-components';

import LazyImage from '@/components/LazyImage';

import media from '@/styles/media';

import { getImageUrl, getImageTransformation } from '@/utils/images';

const Image = styled(LazyImage)`
  ${media.md`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `}

  img {
    ${media.lg`
      width: auto;
      height: 100%;
    `}
  }
`;

export default ({ filename, alt, ...props }) => {
  return (
    <Image
      alt={alt}
      src={getImageUrl(filename)}
      sizes="(min-width: 52em) 50vw, 100vw"
      imgixParams={getImageTransformation(filename, 'detail')}
      {...props}
    />
  );
};
