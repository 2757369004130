import React, { useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import styled from 'styled-components';

import CartContext from '@/context/CartContext';

import { GET_CHECKOUT, ADD_LINE_ITEM } from '@/data/checkout';

import QuantityInput from '@/components/QuantityInput';

import Button from '@/atoms/Button';

import media from '@/styles/media';
import scale from '@/styles/scale';

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args));

const Form = styled.form`
  display: flex;
  flex: 0 0 auto;
  margin-top: ${scale(1)};

  ${media.md`
    margin-top: 0;
  `}
`;

const Quantity = styled(QuantityInput)`
  margin-right: ${scale(0.5)};
`;

const AddToCart = ({
  isLoading,
  addToCart,
  quantityAvailable,
  quantityInCart,
  onAddToCart,
  ...props
}) => {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = event => {
    setQuantity(parseInt(event.target.value, 10));
  };

  // we want to cap quantity at 12
  const quantityRemaining = quantityAvailable - quantityInCart;
  const maxQuantity = Math.min(quantityRemaining, 12);

  const isButtonDisabled = isLoading || maxQuantity === 0;

  const handleSubmit = event => {
    !isLoading && addToCart(quantity);
    setQuantity(Math.min(quantityRemaining - quantity, 1));
    event.preventDefault();
  };

  const onAddToCartQty = () => onAddToCart && onAddToCart(quantity);

  return (
    <Form onSubmit={callAll(onAddToCartQty, handleSubmit)} {...props}>
      <Quantity
        min={Math.min(1, maxQuantity, quantity)}
        max={maxQuantity}
        value={quantity}
        onChange={handleQuantityChange}
      />

      <Button as="button" type="submit" disabled={isButtonDisabled}>
        Add to Bag
      </Button>
    </Form>
  );
};

export default ({ variantId, ...props }) => (
  <CartContext.Consumer>
    {({ checkoutId }) => (
      <Query
        query={GET_CHECKOUT}
        variables={{ checkoutId }}
        skip={!checkoutId}
        partialRefetch
      >
        {({ data }) => {
          const lineItems = data && data.node && data.node.lineItems.edges;

          const lineItemForVariant = lineItems
            ? lineItems.filter(item => item.node.variant.id === variantId)
            : [];

          const quantityInCart = lineItemForVariant.length
            ? lineItemForVariant[0].node.quantity
            : 0;

          return (
            <Mutation mutation={ADD_LINE_ITEM}>
              {(addToCart, { loading }) => (
                <AddToCart
                  addToCart={quantity =>
                    addToCart({
                      variables: {
                        checkoutId,
                        variantId,
                        quantity,
                      },
                    })
                  }
                  isLoading={loading}
                  quantityInCart={quantityInCart}
                  {...props}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    )}
  </CartContext.Consumer>
);
