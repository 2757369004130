import React from 'react';
import styled from 'styled-components';

import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import Grid from '@/atoms/Grid';

import media from '@/styles/media';
import scale from '@/styles/scale';

const Container = styled(Grid.Item)`
  order: 10;
  margin-bottom: ${scale(2.5)};

  ${media.md`
    margin-bottom: 0;
  `}
`;

const List = styled.ul`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.color.base};
  padding-top: ${scale(2.25)};
  padding-bottom: ${scale(1.5)};

  ${media.md`
    border-top: 0;
    padding-bottom: ${scale(2.75)};
  `}
`;

const ListItem = styled.li`
  width: 50%;
  padding-right: ${scale(1)};
  list-style: none;
`;

const Title = styled(Heading).attrs({ h: 5 })`
  margin-bottom: ${scale(0.125)};
`;

const Content = styled(Text).attrs({ t: -2 })`
  margin-bottom: ${scale(0.875)};
`;

export default ({
  legalName,
  street,
  postcode,
  city,
  country = { slug: null, name: null },
  ...props
}) => {
  if (country.slug !== 'georgia' && !legalName) {
    return null;
  }

  return (
    <Container w={[1]} {...props}>
      <List>
        {country.slug !== 'georgia' ? (
          <ListItem>
            <Title as="h5">Produced and bottled by</Title>
            <Content>
              {legalName && (
                <>
                  {legalName}
                  <br />
                </>
              )}
              {street && (
                <>
                  {street}
                  <br />
                </>
              )}
              {(postcode || city) && (
                <>
                  {[postcode, city].join(' ')}
                  <br />
                </>
              )}
              {country.name}
            </Content>
          </ListItem>
        ) : (
          <ListItem>
            <Title as="h5">Imported by</Title>
            <Content>
              Olaf Schindler
              <br />
              Isestr. 27
              <br />
              20144 Hamburg
              <br />
              Germany
            </Content>
          </ListItem>
        )}

        {country.slug && (
          <ListItem>
            <Title as="h5">Country of Origin</Title>
            <Content>{country.name}</Content>
          </ListItem>
        )}
      </List>
    </Container>
  );
};
