import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import ArrowButton from '@/atoms/ArrowButton';

import media from '@/styles/media';
import scale from '@/styles/scale';

import TagHeading from '@/components/TagHeading';

const Header = styled(Grid)`
  margin-bottom: ${scale(1.5)};
`;

const ShowAll = styled(Grid.Item)`
  display: flex;

  ${media.md`
    justify-content: flex-end;
    padding-bottom: ${scale(0.15)};
  `}
`;

const ShowAllButton = styled(ArrowButton)`
  margin-top: ${scale(1)};
  align-self: flex-end;
`;

export default ({ headline, navigationTags, ...props }) => {
  let headlineType = 'default';

  if (headline && typeof headline === 'object') {
    headlineType = 'tagHeadline';
  }

  return (
    <Header as="header" {...props}>
      <Grid.Item w={[1, 1, 3 / 4]}>
        {headlineType === 'default' && (
          <Heading h="h2Display">{headline}</Heading>
        )}

        {headlineType === 'tagHeadline' && (
          <TagHeading headlineParts={headline} />
        )}
      </Grid.Item>

      <ShowAll w={[1, 1, 1 / 4]}>
        <ShowAllButton tags={navigationTags} icon="arrowRight">
          Show All
        </ShowAllButton>
      </ShowAll>
    </Header>
  );
};
