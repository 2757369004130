import React, { useContext } from 'react';

import TagsDictContext from '@/context/TagsDictContext';

import ProductList from '@/components/ProductList';

import { flatten } from '@/utils/arrayTransforms';

const getHeadline = (parts, navigationTags) =>
  parts
    .map(part => {
      const tags = flatten(
        part.tagTypes.map(tagType =>
          navigationTags.filter(tag => tag.type === tagType)
        )
      ).filter(tag => tag !== null);

      if (tags.length > 0) {
        return {
          ...part,
          tags,
        };
      }

      return null;
    })
    .filter(part => part !== null);

const getTagsFromRealtionInfo = (relationInfo, dict) => {
  const tags = [];

  if (relationInfo.type === 'NON_SPARKLING' && relationInfo.color) {
    tags.push({
      type: 'type',
      slug: dict.type[relationInfo.color].slug,
      title: dict.type[relationInfo.color].title,
    });
  } else if (relationInfo.type !== 'NON_SPARKLING') {
    tags.push({
      type: 'type',
      slug: dict.type.SPARKLING.slug,
      title: dict.type.SPARKLING.title,
    });
  }

  if (relationInfo.body) {
    tags.push({
      type: 'body',
      slug: dict.body[relationInfo.body].slug,
      title: dict.body[relationInfo.body].title,
    });
  }

  if (relationInfo.styles.length) {
    const styles = relationInfo.styles.map(style => ({
      type: 'style',
      slug: style.slug,
      title: style.name,
    }));

    tags.push(...styles);
  }

  return tags;
};

export default ({ products, relationInfo, headlineParts, ...props }) => {
  const [, enumTagsDict] = useContext(TagsDictContext);

  const baseNavigationTags = getTagsFromRealtionInfo(
    relationInfo,
    enumTagsDict
  );

  const headline = getHeadline(headlineParts, baseNavigationTags);

  return (
    <ProductList
      baseNavigationTags={baseNavigationTags}
      products={products}
      headline={headline}
      {...props}
    />
  );
};
