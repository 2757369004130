import React from 'react';
import styled from 'styled-components';

import Heading from '@/atoms/Heading';
import Grid from '@/atoms/Grid';

import TagList from '@/components/TagList';

import dict from '@/dict';
import { getAbvString } from '@/utils/productData';

import media from '@/styles/media';
import scale from '@/styles/scale';
import FormattedText from '@/atoms/FormattedText';

const Container = styled(Grid.Item)`
  margin-bottom: ${scale(1.5)};

  ${media.md`
    margin-bottom: ${scale(3.5)};
  `}
`;

const Title = styled(Heading).attrs({ h: 5 })`
  margin-bottom: ${scale(0.125)};
`;

const Content = styled(Heading).attrs({ h: 4 })`
  margin-bottom: ${scale(0.875)};

  ::first-letter {
    text-transform: capitalize;
  }
`;

export default ({
  type,
  color,
  varieties,
  abv,
  body,
  styles,
  description,
  ...props
}) => {
  const typeInfo = [];

  type && type !== 'NON_SPARKLING' && typeInfo.push(dict(type, 'type'));
  color && typeInfo.push(dict(color, 'color'));

  const varietyInfo = varieties
    .map(({ variety, part }) =>
      part ? `${Math.round(part * 100)}% ${variety.name}` : variety.name
    )
    .join(', ');

  const tagsBody = body
    ? [
        {
          title: dict(body, 'body'),
          type: 'body',
          slug: dict(body, 'bodySlug'),
        },
      ]
    : [];

  const tagsStyle = styles.map(style => ({
    title: style.name,
    type: 'style',
    slug: style.slug,
  }));

  return (
    <Container w={[1]} {...props}>
      <dl>
        {typeInfo.length > 0 && (
          <>
            <Title as="dt">Type</Title>
            <Content as="dd">{typeInfo.join(', ')}</Content>
          </>
        )}

        {varieties.length > 0 && (
          <>
            <Title as="dt">Variety</Title>
            <Content as="dd">{varietyInfo}</Content>
          </>
        )}

        {abv && (
          <>
            <Title as="dt">Alc.</Title>
            <Content as="dd">{getAbvString(abv)}</Content>
          </>
        )}

        {(styles.length > 0 || body) && (
          <>
            <Title as="dt">Style</Title>
            <Content as="dd">
              <TagList tags={[...tagsBody, ...tagsStyle]} size="small" />
            </Content>
          </>
        )}
      </dl>

      {description && (
        <FormattedText dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </Container>
  );
};
