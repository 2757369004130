import React from 'react';
import styled from 'styled-components';

import Icon from '@/atoms/Icon';

import scale from '@/styles/scale';
import { buttonLabel } from '@/styles/typography';

const Container = styled.div`
  position: relative;
`;

const SelectArrow = styled(Icon).attrs({ type: 'arrowsUpDown' })`
  position: absolute;
  right: ${scale(0.25)};
  top: ${scale(0.8)};
  pointer-events: none;
`;

const Select = styled.select`
  ${buttonLabel}
  border: 1px solid ${props => props.theme.color.base};
  border-radius: ${props => props.theme.measure.borderRadius.base};
  padding: 0 ${scale(1.75)} 0 ${scale(1)};
  height: ${scale(3)};
  min-width: ${scale(3.75)};
  text-align: center;
  background: ${props => props.theme.color.bg};
  cursor: pointer;
  -webkit-appearance: none;
  outline: none;

  :focus {
    box-shadow: 0 0 0 1px ${props => props.theme.color.base};
  }
`;

export default ({ min = 1, max = 20, value = 1, onChange, ...props }) => {
  const options = [];
  const optionLength = max - min;

  for (let i = 0; i <= optionLength; i += 1) {
    options.push({
      id: `val-${min + i}`,
      value: min + i,
    });
  }

  return (
    <Container {...props}>
      <Select value={value} onChange={onChange}>
        {options.map(option => (
          <option key={option.id} value={option.value}>
            {option.value}
          </option>
        ))}
      </Select>

      <SelectArrow />
    </Container>
  );
};
