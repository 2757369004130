/**
 * Gets alcohol vol. amount (in %)
 * for legal reasons has to be rounded to nearest 0.5
 * and displayed with max. 1 decimal
 *
 * @param {number} amount Raw alc vol amount
 */
export const getAbv = amount => {
  const percentage = Math.round(amount * 100) / 100;

  // round to 0.5
  return Math.round(percentage * 2) / 2;
};

/**
 * Returns formatted alcohol vol. amount
 *
 * @param {number} amount alc vol amount (in %)
 */
export const getAbvString = amount => `${getAbv(amount)}% vol`;
