import React from 'react';
import styled from 'styled-components';

import Heading from '@/atoms/Heading';
import Tag from '@/atoms/Tag';

import media from '@/styles/media';
import scale from '@/styles/scale';

const InlineText = styled.span`
  ${props =>
    props.part === 'before' &&
    `
    padding-right: ${scale(0.375)};
  `}

  ${props =>
    props.part === 'after' &&
    `
    padding-left: ${scale(0.375)};
  `}

  ${media.md`
    ${props =>
      props.part === 'before' &&
      `
      padding-right: ${scale(0.625)};
    `}

    ${props =>
      props.part === 'after' &&
      `
      padding-left: ${scale(0.625)};
    `}
  `}
`;

const InlineTag = styled(Tag)`
  vertical-align: middle;
  margin-top: -${scale(0.3)};
  margin-left: ${scale(0.125)};
  margin-right: ${scale(0.125)};
  transition: 0.1s transform ease-out;

  ${media.md`
    margin-top: -${scale(0.5)};
  `}

  :nth-child(2n) {
    transform: rotate(1deg);

    :hover,
    :focus {
      transform: rotate(2deg);
    }
  }

  :nth-child(2n + 1) {
    transform: rotate(-1deg);

    :hover,
    :focus {
      transform: rotate(-2deg);
    }
  }
`;

export default ({ headlineParts, as = 'h1', ...props }) => (
  <Heading h="h2Display" as={as} {...props}>
    {headlineParts.map(sentence => (
      <span key={`sentence-${sentence.before}`}>
        {['before', 'tags', 'after'].map(part => {
          if (part === 'tags' && sentence[part]) {
            return sentence[part].map(tag => (
              <InlineTag size="large" key={`${tag.type}-${tag.slug}`} {...tag}>
                {tag.title}
              </InlineTag>
            ));
          }

          if (sentence[part]) {
            return (
              <InlineText key={`${sentence.before}-${part}`} part={part}>
                {sentence[part]}
              </InlineText>
            );
          }

          return null;
        })}
      </span>
    ))}
  </Heading>
);
