import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';

import ProductGrid from '@/components/ProductGrid';

import Header from './Header';

import scale from '@/styles/scale';

const Description = styled(Grid.Item)`
  margin-bottom: ${scale(2)};
`;

const Container = styled(Grid.Container)`
  && {
    ${props =>
      props.noContainerPadding &&
      `
    padding-left: 0;
    padding-right: 0;
  `}
  }
`;

export default ({
  noContainerPadding = false,
  baseNavigationTags,
  products,
  headline,
  description,
  ...props
}) => (
  <Container noContainerPadding={noContainerPadding} as="section" {...props}>
    {headline && (
      <Header headline={headline} navigationTags={baseNavigationTags} />
    )}

    {description && (
      <Grid>
        <Description w={[1, 2 / 3]}>
          <Text>{description}</Text>
        </Description>
      </Grid>
    )}

    {products && (
      <ProductGrid
        baseNavigationTags={baseNavigationTags}
        products={products}
        hideLastOnMobile
      />
    )}
  </Container>
);
