import React from 'react';
import styled from 'styled-components';

import media from '@/styles/media';
import scale from '@/styles/scale';

import Image from './Image';

const Container = styled.div`
  overflow: hidden;

  ${media.md`
    position: sticky;
    top: ${props => scale(props.theme.measure.header.height[1])};
    height: calc(100vh - ${props =>
      scale(props.theme.measure.header.height[1])});
  `}
`;

export default ({ images, alt, ...props }) => (
  <Container {...props}>
    {images.map(image => (
      <Image alt={alt} key={image} filename={image} />
    ))}
  </Container>
);
