import React from 'react';
import styled from 'styled-components';

import TagLink from '@/atoms/TagLink';
import Heading from '@/atoms/Heading';
import CountryLabel from '@/atoms/CountryLabel';
import Text from '@/atoms/Text';
import Grid from '@/atoms/Grid';

import { getWinemakerString, getRegionString } from '@/utils/stringTransforms';

import media from '@/styles/media';
import scale from '@/styles/scale';

const Header = styled(Grid.Item)`
  margin-bottom: ${scale(2.25)};

  ${media.md`
    margin-bottom: ${scale(2)};
  `}
`;

const Title = styled(Heading).attrs({ h: 'productLarge' })`
  margin-bottom: ${scale(0.5)};

  ${media.md`
    margin-top: ${scale(3)};
    margin-bottom: ${scale(0.25)};
  `}

  ${media.lg`
    margin-top: ${scale(4)};
  `}
`;

const Producer = styled(Heading).attrs({ h: 4 })`
  margin-bottom: ${scale(0.125)};

  ${media.md`
    margin-bottom: ${scale(0.25)};
  `}
`;

const Region = styled(Heading).attrs({ h: 4 })`
  margin-top: ${scale(1.25)};

  ${media.md`
    margin-top: ${scale(2)};
  `}
`;

export default ({
  name,
  vintage,
  producer,
  producerNavigationTags,
  winemakers = [],
  regions,
  ...props
}) => {
  const title = vintage ? [name, vintage].join(' ') : name;

  const region = getRegionString(regions);
  const { slug: countrySlug } = regions.length && regions[0].country;

  return (
    <Header as="header" w={[1]} {...props}>
      <Title as="h1">{title}</Title>

      <Producer as="h2">
        <TagLink tags={producerNavigationTags}>{producer}</TagLink>
      </Producer>

      {winemakers.length > 0 && <Text>{getWinemakerString(winemakers)}</Text>}

      {regions.length > 0 && (
        <Region>
          {region} {countrySlug && <CountryLabel countrySlug={countrySlug} />}
        </Region>
      )}
    </Header>
  );
};
