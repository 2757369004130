import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';

import scale from '@/styles/scale';
import media from '@/styles/media';

import { getSanitizedPrice } from '@/utils/pricing';

import Header from './Header';
import MainAttributes from './MainAttributes';
import SecondaryAttributes from './SecondaryAttributes';
import LegalInfo from './LegalInfo';
import PurchaseOptions from './PurchaseOptions';

const HalfpageContainer = styled(Grid.Container)`
  margin-left: 0;

  ${media.md`
    max-width: ${props =>
      scale((props.theme.grid.maxWidth + props.theme.grid.outerMargin[3]) / 2)};
  `}
`;

export default ({ wine, ...props }) => {
  const { producer, shopifyProduct } = wine;

  const producerNavigationTags = [
    {
      type: 'producer',
      slug: producer.slug,
    },
  ];

  const isAvailable = shopifyProduct && shopifyProduct.availableForSale;
  const price =
    shopifyProduct && shopifyProduct.priceRange.minVariantPrice.amount;
  const variantId = shopifyProduct && shopifyProduct.variants.edges[0].node.id;
  const quantityAvailable =
    shopifyProduct && shopifyProduct.variants.edges[0].node.inventoryQuantity;

  const description = shopifyProduct && shopifyProduct.descriptionHtml;

  const nameString = wine.vintage
    ? [wine.name, wine.vintage].join(' ')
    : wine.name;

  const handleAddToCart = qty => {
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [
            {
              name: nameString,
              id: wine.sku,
              sku: wine.sku,
              handle: `${wine.slug}-${producer.slug}`,
              price:
                isAvailable &&
                getSanitizedPrice(
                  shopifyProduct.priceRange.minVariantPrice.amount
                ),
              brand: producer.name,
              quantity: qty,
            },
          ],
        },
      },
    });
  };

  return (
    <HalfpageContainer {...props}>
      <Grid>
        <Header
          name={wine.name}
          vintage={wine.vintage}
          producer={producer.name}
          producerNavigationTags={producerNavigationTags}
          winemakers={producer.winemakers}
          regions={producer.regions}
        />

        <PurchaseOptions
          isAvailable={isAvailable}
          quantityAvailable={quantityAvailable}
          variantId={variantId}
          price={price}
          bottleSize={wine.bottleSize}
          onAddToCart={handleAddToCart}
        />

        <MainAttributes
          type={wine.type}
          color={wine.color}
          varieties={wine.wineVarieties}
          abv={wine.abv}
          body={wine.body}
          styles={wine.styles}
          description={description}
        />

        <SecondaryAttributes
          sulfiteLevel={wine.sulfiteLevel}
          drinkBefore={wine.drinkBefore}
          drinkingTemperature={wine.drinkingTemperature}
          decant={wine.decant}
          turnBottle={wine.turnBottle}
          productionVolume={wine.productionVolume}
        />

        <LegalInfo
          legalName={producer.legalName}
          street={producer.street}
          postcode={producer.postcode}
          city={producer.city}
          country={producer.regions.length && producer.regions[0].country}
        />
      </Grid>
    </HalfpageContainer>
  );
};
