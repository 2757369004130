import React from 'react';
import styled from 'styled-components';

import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import Grid from '@/atoms/Grid';

import dict from '@/dict';

import { getServingInfoString } from '@/utils/stringTransforms';

import media from '@/styles/media';
import scale from '@/styles/scale';

const Container = styled(Grid.Item)`
  order: 9;

  ${media.md`
    margin-bottom: ${scale(1.5)};
  `}
`;

const List = styled.dl`
  border: 1px solid ${props => props.theme.color.base};
  border-width: 1px 0;
  padding-top: ${scale(2.25)};
  padding-bottom: ${scale(1.5)};

  ${media.md`
    border-top: 0;
    padding-top: 0;
    padding-bottom: ${scale(2.75)};
  `}
`;

const Title = styled(Heading).attrs({ h: 5 })`
  margin-bottom: ${scale(0.125)};
`;

const Content = styled(Text)`
  margin-bottom: ${scale(0.875)};
`;

export default ({
  sulfiteLevel,
  drinkBefore,
  drinkingTemperature,
  decant,
  turnBottle,
  productionVolume,
  ...props
}) => {
  const servingInfo = getServingInfoString(
    drinkingTemperature,
    turnBottle,
    decant
  );

  const drinkBeforeInfo =
    drinkBefore && drinkBefore === new Date().getFullYear()
      ? 'Best enjoyed in the next 12 months'
      : `Make sure it’s gone by ${drinkBefore}`;

  return (
    <Container w={[1]} {...props}>
      <List>
        {productionVolume && (
          <>
            <Title as="dt">Total Production</Title>
            <Content as="dd">{productionVolume} bottles</Content>
          </>
        )}

        {servingInfo && (
          <>
            <Title as="dt">Serving</Title>
            <Content as="dd">{servingInfo}</Content>
          </>
        )}

        {drinkBefore && (
          <>
            <Title as="dt">Storage</Title>
            <Content as="dd">{drinkBeforeInfo}</Content>
          </>
        )}

        <Title as="dt">Allergies</Title>
        <Content as="dd">
          Contains sulfites
          {sulfiteLevel && ` (${dict(sulfiteLevel, 'sulfites')})`}
        </Content>
      </List>
    </Container>
  );
};
