import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import scale from '@/styles/scale';
import media from '@/styles/media';

import dict from '@/dict';

import { getPriceString, getPricePerLiterString } from '@/utils/pricing';

import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

const Container = styled.div`
  flex: 1 0 auto;
  padding-top: ${scale(0.5)};
  padding-right: ${scale(1)};

  ${media.md`
    padding-top: ${scale(0.375)};
  `}
`;

const InlineLink = styled(Link)`
  && {
    border-bottom: 1px solid ${props => props.theme.color.base};

    :hover,
    :focus {
      color: ${props => props.theme.color.grayDark};
      border-color: ${props => props.theme.color.grayDark};
    }
  }
`;

const Details = styled.div`
  padding-top: ${scale(0.5)};
`;

const PricePerLiter = styled(Text)`
  padding-left: ${scale(0.375)};
  color: ${props => props.theme.color.gray};
`;

export default ({ price, bottleSize }) => (
  <Container>
    <Heading h={2} as="p">
      {getPriceString(price)}
    </Heading>

    <Details>
      {bottleSize && (
        <Text>
          {dict(bottleSize, 'bottle')}

          <PricePerLiter t={-2} as="span">
            ({getPricePerLiterString(price, bottleSize)})
          </PricePerLiter>
        </Text>
      )}

      <Text t={-2}>
        incl. 19% VAT, plus <InlineLink to="/shipping/">shipping</InlineLink>
      </Text>
    </Details>
  </Container>
);
